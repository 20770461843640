import React, { useEffect, useState } from 'react'
import Pagination from '../layout/Pagination'
import cookie from 'react-cookies'
import { formatMoney } from '../helpers/utils'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import { getTimestampFromDateTime } from '../helpers/formatDate'
import SearchInput from '../layout/SearchInput'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePicker from 'react-multi-date-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilter'
import moment from 'moment'
import fetchWithAuth from '../helpers/fetchWithAuth'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

function SettlementsIndex() {
  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingFilter, setIsFetchingFilter] = useState(false)

  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(null)
  const [perPage, setPerPage] = useState(25)
  const [perPageSettlements, setPerPageSettlement] = useState(50)

  const [totalPages, setTotalPages] = useState(0)
  const [title, setTitle] = useState('Liquidaciones')
  const [data, setData] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [settlements, setSettlements] = useState([])

  const [fetchDataStatus, setFetchDataStatus] = useState(true)
  const [filters, setFilters] = useState(new Map())
  const [filtersDic, setFiltersDic] = useState({ 'Creado Por': 'user_uid' })
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [labelToIdMapping, setLabelToIdMapping] = useState({})
  const [allSelectedOptions, setAllSelectedOptions] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [dateQuery, setDateQuery] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [dataFilter, setDataFilter] = useState([])

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const filtersDisabled = !settlements || settlements.length === 0

  const setQueryState = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myQuery = urlParams.get('query')
    if (myQuery) {
      setQuery(myQuery)
    } else {
      setQuery('')
    }
  }

  const setPaginationParamsState = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myPage = urlParams.get('pagina')
    if (myPage) {
      setCurrentPage(myPage)
    } else {
      setCurrentPage(1)
    }
  }

  const setAllSelectedOptionsFunc = (identifier, selectedLabels) => {
    setAllSelectedOptions(
      (prevAllSelectedOptions) => {
        const selectedFullNames = selectedLabels.map((label) => {
          const parts = label.split(' - ')
          const fullName = parts.length > 1 ? parts[1] : label
          return fullName
        })
        const newAllSelectedOptions = new Map(prevAllSelectedOptions).set(
          identifier,
          selectedFullNames
        )
        return newAllSelectedOptions
      },
      () => {
        buildFiltersQuery()
      }
    )
  }

  const buildFiltersQuery = () => {
    const values = allSelectedOptions.get('Creado Por') || []
    const queryParam =
      values.length > 0 ? `&client_uid=${encodeURIComponent(values.join(','))}` : ''
    setFilterQuery(queryParam)
  }

  const fetchClientsFromServer = async () => {
    const response = await fetchWithAuth(
      `${BACKEND_URL}/settlements?client_names_only=true&business_tenant_uid=${cookie.load(
        'business_tenant_uid'
      )}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status != 200) {
      setData([])
      setTotalPages(0)
    } else {
      let json = response
      setSettlements(json['result'])
    }
    setIsFetching(false)
  }

  const fetchSettlementFromServer = async () => {
    setIsFetchingFilter(false)
    const response = await fetch(
      BACKEND_URL +
        '/settlements' +
        '?page=' +
        currentPage +
        '&per_page=' +
        perPage +
        (filterQuery || dateQuery ? '&filter_search=true' : '') +
        (filterQuery ? filterQuery : '') +
        (dateQuery ? dateQuery : '') +
        (query ? '&q=' + query : '') +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status != 200) {
      setData([])
      setTotalPages(0)
    } else {
      let data = await response.json()
      setData(data['result'])
      setTotalPages(Math.ceil(data['count'] / perPage))
    }
    setIsFetchingFilter(false)
  }

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    if (target.id === 'first-page') {
      if (parseInt(currentPage) !== 1) {
        window.location = '/liquidaciones?pagina=1' + '&query=' + query
      }
    } else if (target.id === 'last-page') {
      if (parseInt(currentPage) !== totalPages) {
        window.location = '/liquidaciones?pagina=' + totalPages + '&query=' + query
      }
    } else if (target.id === 'previous-page') {
      if (parseInt(currentPage) !== 1) {
        window.location = '/liquidaciones?pagina=' + (parseInt(currentPage) - 1) + '&query=' + query
      }
    } else if (target.id === 'next-page') {
      if (parseInt(currentPage) !== totalPages) {
        window.location = '/liquidaciones?pagina=' + (parseInt(currentPage) + 1) + '&query=' + query
      }
    }
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale submit form
      event.preventDefault()
      const _query = event.target.value
      if (_query !== '') {
        window.location = '/liquidaciones?query=' + _query
      }
    }
  }

  const showModel = (uuid) => {
    window.open('/liquidaciones/detalles/' + uuid, '_blank')
  }

  const processSettlementsForFilter = () => {
    let labelToIdMappingTemp = {}

    const createdByOptions = settlements
      .map((settlement) => {
        const label = `${settlement.user_uid} - ${settlement.user_full_name}`
        labelToIdMappingTemp[label] = settlement.user_full_name
        return label
      })
      .filter((value, index, self) => self.indexOf(value) === index)

    setFilters([{ key: 'Creado Por', value: createdByOptions }])
    setLabelToIdMapping(labelToIdMappingTemp)
    setFetchDataStatus((prevStatus) => ({ ...prevStatus, filters: false }))
  }

  const resetFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setDateQuery(null)
    setSelectedDates(null)
    setSearchQuery('')
    setDataFilter('')
    setQuery('')
    setResetReactFilters(!resetReactFilters)
    fetchSettlementFromServer()
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()
    if (!datesObj || datesObj.length == 0) {
      setDateQuery(null)
      setSelectedDates(null)
    } else if (datesObj) {
      datesObj.map((date) => {
        formatedDates.push(new Date(date.unix * 1000))
      })
      setSelectedDates(datesObj)
      //When there is a date range
      if (formatedDates.length > 1) {
        if (formatedDates[0] < formatedDates[1]) {
          startDate = new Date(formatedDates[0])
          endDate = new Date(formatedDates[1])
        } else if (formatedDates[0] > formatedDates[1]) {
          startDate = new Date(formatedDates[1])
          endDate = new Date(formatedDates[0])
        }
      }
      //When is only one date
      else if (formatedDates.length == 1) {
        startDate = new Date(formatedDates[0])
        endDate = new Date(formatedDates[0])
      }
      queryParam = `&start_date=${startDate.toLocaleDateString()}&end_date=${endDate.toLocaleDateString()}`
      setDateQuery(queryParam)
    }
  }

  useEffect(() => {
    if (settlements) {
      processSettlementsForFilter()
    }
  }, [settlements])

  useEffect(() => {
    if (allSelectedOptions) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  useEffect(() => {
    fetchSettlementFromServer()
  }, [query, filterQuery, dateQuery, currentPage])

  useEffect(() => {
    setQueryState()
    setPaginationParamsState()
  }, [])

  useEffect(() => {
    fetchClientsFromServer()
  }, [])

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar onToggleCollapse={handleToggleCollapse} collapsed={collapsed} />
        </div>
        <div onClick={handlePaginationClick}>
          {isFetching && (
            <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
              <div className="index-header">
                <br />
                <p>Obteniendo datos ...</p>
              </div>
            </div>
          )}
          {!isFetching && (
            <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
              <div className="index-header detailed-container">
                <h2>{title}</h2>
              </div>
              <div className="index-table">
                <div className="show-area">
                  <h4 className="">Filtrar liquidaciones por:</h4>
                  <div className="filters-search-container mb-10">
                    <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                    <div className="filters-container">
                      {Array.from(filters).map((filter, indx) => (
                        <div key={`flr-${indx}`} className="mr-10">
                          <ReactFilter
                            className="mr-10"
                            title={filter.key}
                            identifier={filter.key}
                            options={filter.value}
                            resetWatcher={resetReactFilters}
                            disabled={filtersDisabled}
                            onChange={(e) => {
                              let so = new Map(allSelectedOptions)
                              let selectedFullNames

                              if (e.identifier === 'Creado Por') {
                                selectedFullNames = e.selectedOptions.map(
                                  (option) => labelToIdMapping[option]
                                )
                                so.set(e.identifier, selectedFullNames)
                              } else {
                                so.set(e.identifier, e.selectedOptions)
                              }

                              setAllSelectedOptions(so)
                            }}
                          />
                        </div>
                      ))}
                      <DatePicker
                        disabled={filtersDisabled}
                        range
                        rangeHover
                        dateSeparator=" - "
                        months={[
                          'Enero',
                          'Febrero',
                          'Marzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre'
                        ]}
                        weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                        format="DD MMM"
                        weekStartDayIndex={1}
                        highlightToday={false}
                        numberOfMonths={2}
                        maxDate={moment().local().endOf('day').toDate()}
                        onChange={(dateObjects) => handleDateFilter(dateObjects)}
                        value={selectedDates}
                        plugins={[
                          <Toolbar
                            position="bottom"
                            names={{
                              deselect: 'Deseleccionar'
                            }}
                            sort={['deselect']}
                          />
                        ]}
                        render={(value, openCalendar) => {
                          return (
                            <div
                              key={`flr-date`}
                              className={`mr-10 ${filtersDisabled ? 'react-filter-disabled' : ''}`}
                            >
                              <div className="react-filter-box filter-input-width-datepicker">
                                <div
                                  title="Rango de fechas"
                                  onClick={!filtersDisabled ? openCalendar : undefined}
                                  className="react-filter-box-clickable-zone"
                                >
                                  <div className="react-filter-titles">
                                    {value?.length > 0 && (
                                      <>
                                        {Array.isArray(value) ? (
                                          <div className="filter-title-overflow">
                                            {value.join(' - ')}
                                          </div>
                                        ) : (
                                          <div className="filter-title-overflow">{value}</div>
                                        )}
                                        <FontAwesomeIcon
                                          style={{ color: 'ef823a' }}
                                          icon={faCalendar}
                                        />
                                      </>
                                    )}
                                    {value?.length == 0 && (
                                      <>
                                        <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                        <FontAwesomeIcon
                                          style={{ color: filtersDisabled ? '#D5D5D5' : '#597fa9' }}
                                          icon={faCalendar}
                                        />{' '}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }}
                      />
                    </div>

                    <div onKeyDown={handleSearch} className="search-input-containter mb-40">
                      <SearchInput
                        query={query}
                        onSearch={(newQuery) => {
                          setQuery(newQuery)
                          fetchSettlementFromServer()
                        }}
                        placeholder="Buscar liquidación por código o nombre del usuario"
                      />
                    </div>
                  </div>
                  <div className="mb-20">
                    {(filterQuery || dateQuery || searchQuery) && (
                      <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                        Restablecer filtros aplicados
                      </a>
                    )}
                  </div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th style={{ verticalAlign: 'middle', width: '25%' }}>
                          <span className="th-header-text">FECHA Y HORA</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', width: '25%' }}>
                          <span className="th-header-text"># DE LIQUIDACIÓN</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', width: '25%' }}>
                          <span className="th-header-text">CREADO POR</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '25%' }}>
                          <span className="th-header-text">TOTAL COBRADO</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(dataFilter.length > 0 ? dataFilter : data).map((model, index) => (
                        <tr
                          onClick={() => showModel(model.uuid)}
                          className="cursor-pointer"
                          key={index}
                        >
                          <td className="td-text-limited">
                            {getTimestampFromDateTime(model.collected_at)}
                          </td>
                          <td className="td-text-limited">{model.uid}</td>
                          <td className="td-text-limited">
                            {model.user_uid} - {model.user_full_name}
                          </td>
                          <td
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%'
                            }}
                          >
                            {formatMoney(model.total_amount)}
                          </td>
                        </tr>
                      ))}
                      {dataFilter.length === 0 && data.length === 0 && (
                        <tr>
                          <td className="td-text-limited">No hay resultados</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <nav className="index-table">
                {totalPages > 1 && (
                  <Pagination current_page={currentPage} total_pages={totalPages} />
                )}
              </nav>
            </div>
          )}
        </div>
      </div>
      <div>
        <NavBar activeItem="Liquidaciones" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}

export default SettlementsIndex
